import InputMask from 'inputmask';

function getTransactionId() {
    const match = window.location.search.match(/^\?transaction_id=(.+)$/);
    return (match) ? match[1] : '';
}

function handleInputPhone() {
    const phone = document.getElementById('id_phone');
    const mask = phone.getAttribute('placeholder').replace(/_/g, '9');
    InputMask(mask).mask(phone);
}

function resetForm() {
    document.getElementById('id_name').value = ''
    document.getElementById('id_phone').value = ''
    document.getElementById('id_city').value = '';
    document.getElementById('id_checkbox').checked = false;
}


/*******************************************INDEX**********************************************************************/

if (document.getElementById('main')) {
    const formContainer = document.getElementById('form-container');
    const form = document.getElementById('form');
    const submit = document.getElementById('form-submit');
    const buttonClose = document.getElementById('button-close');

    document.getElementById('id_transaction_id').value = getTransactionId();
    submit.disabled = false;

    handleInputPhone();

    form.addEventListener('focusin', (event) => {
        event.target.classList.remove('error');
    });
    form.addEventListener('change', (event) => {
        event.target.classList.remove('error');
    });

    submit.addEventListener('click', (event) => {
        event.preventDefault();
        submit.disabled = true;

        fetch('/', {
            method: 'POST',
            body: new FormData(form),
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
        }).then((data) => {
            let success = data['success'];
            switch (success) {
                case 0:
                    form.firstElementChild.innerHTML = data['html'];
                    handleInputPhone();
                    break;
                case 1:
                    resetForm();
                    formContainer.classList.add('success');
                    break;
                default:
                    break;
            }
        }).catch((error) => {
        }).finally(() => submit.disabled = false);
    });

    buttonClose.addEventListener('click', (event) => {
        formContainer.classList.remove('success');
    });
}
